*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.left-2\/4 {
  left: 50%;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-mt-\[123px\] {
  margin-top: -123px;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[150px\] {
  height: 150px;
}

.h-\[280px\] {
  height: 280px;
}

.h-\[350px\] {
  height: 350px;
}

.h-\[40px\] {
  height: 40px;
}

.h-auto {
  height: auto;
}

.max-h-0 {
  max-height: 0;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-9\/12 {
  width: 75%;
}

.w-\[40px\] {
  width: 40px;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-100 {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.overflow-hidden {
  overflow: hidden;
}

.bg-darkred {
  --tw-bg-opacity: 1;
  background-color: rgb(90 23 23 / var(--tw-bg-opacity));
}

.bg-lightgrey {
  --tw-bg-opacity: 1;
  background-color: rgb(247 246 246 / var(--tw-bg-opacity));
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(235 30 30 / var(--tw-bg-opacity));
}

.bg-\[url\(\'\.\.\/img\/biographe-duo-nova\.jpg\'\)\] {
  background-image: url("biographe-duo-nova.8396f87f.jpg");
}

.bg-\[url\(\'\.\.\/img\/presskit\.webp\'\)\] {
  background-image: url("presskit.60244040.webp");
}

.bg-\[url\(\'\.\.\/img\/repertoire\.webp\'\)\] {
  background-image: url("repertoire.d96d1b0d.webp");
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.font-raleway {
  font-family: Raleway, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-darkred {
  --tw-text-opacity: 1;
  color: rgb(90 23 23 / var(--tw-text-opacity));
}

.text-grey {
  --tw-text-opacity: 1;
  color: rgb(55 55 55 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-80 {
  opacity: .8;
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-display: swap;
  font-family: Raleway;
  font-style: normal;
  font-weight: 200;
  src: url("raleway-v29-latin-regular.9c602b3e.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  src: url("raleway-v29-latin-regular.9c602b3e.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  src: url("raleway-v29-latin-600.72e15e0e.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Raleway;
  font-style: normal;
  font-weight: 800;
  src: url("raleway-v29-latin-800.53ca458d.woff2") format("woff2");
}

body {
  font-family: Raleway, serif;
}

.hover\:text-darkred:hover {
  --tw-text-opacity: 1;
  color: rgb(90 23 23 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:drop-shadow-xl:hover {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media not all and (width >= 1024px) {
  .max-lg\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .max-lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .max-lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .max-lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .max-lg\:hidden {
    display: none;
  }

  .max-lg\:h-3 {
    height: .75rem;
  }

  .max-lg\:flex-col {
    flex-direction: column;
  }

  .max-lg\:gap-x-1 {
    column-gap: .25rem;
  }

  .max-lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .max-lg\:bg-bottom {
    background-position: bottom;
  }

  .max-lg\:bg-top {
    background-position: top;
  }

  .max-lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .max-lg\:pt-2 {
    padding-top: .5rem;
  }

  .max-lg\:pt-4 {
    padding-top: 1rem;
  }

  .max-lg\:text-center {
    text-align: center;
  }

  .max-lg\:font-bold {
    font-weight: 700;
  }
}

@media (width >= 640px) {
  .sm\:w-\[600px\] {
    width: 600px;
  }

  .sm\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }
}

@media (width >= 768px) {
  .md\:-mt-\[255px\] {
    margin-top: -255px;
  }

  .md\:ml-10 {
    margin-left: 2.5rem;
  }

  .md\:h-8 {
    height: 2rem;
  }

  .md\:w-\[768px\] {
    width: 768px;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:-mt-40 {
    margin-top: -10rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:ml-24 {
    margin-left: 6rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:h-\[1024px\] {
    height: 1024px;
  }

  .lg\:h-\[340px\] {
    height: 340px;
  }

  .lg\:h-\[60px\] {
    height: 60px;
  }

  .lg\:h-\[650px\] {
    height: 650px;
  }

  .lg\:w-1\/6 {
    width: 16.6667%;
  }

  .lg\:w-10\/12 {
    width: 83.3333%;
  }

  .lg\:w-11\/12 {
    width: 91.6667%;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:w-5\/6 {
    width: 83.3333%;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }

  .lg\:w-\[60px\] {
    width: 60px;
  }

  .lg\:w-\[938px\] {
    width: 938px;
  }

  .lg\:rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:whitespace-nowrap {
    white-space: nowrap;
  }

  .lg\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }

  .lg\:from-white {
    --tw-gradient-from: #fff var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:from-\[92\%\] {
    --tw-gradient-from-position: 92%;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:p-3 {
    padding: .75rem;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:pt-16 {
    padding-top: 4rem;
  }

  .lg\:pt-2 {
    padding-top: .5rem;
  }

  .lg\:pt-3 {
    padding-top: .75rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:leading-8 {
    line-height: 2rem;
  }

  .lg\:tracking-wider {
    letter-spacing: .05em;
  }
}

@media (width >= 1280px) {
  .xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xl\:-mt-\[435px\] {
    margin-top: -435px;
  }

  .xl\:w-10\/12 {
    width: 83.3333%;
  }

  .xl\:w-5\/12 {
    width: 41.6667%;
  }

  .xl\:w-7\/12 {
    width: 58.3333%;
  }

  .xl\:w-\[1173px\] {
    width: 1173px;
  }

  .xl\:pb-1 {
    padding-bottom: .25rem;
  }

  .xl\:pt-5 {
    padding-top: 1.25rem;
  }

  .xl\:pt-6 {
    padding-top: 1.5rem;
  }

  .xl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .xl\:hover\:bg-lightgrey:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(247 246 246 / var(--tw-bg-opacity));
  }

  .xl\:hover\:text-darkred:hover {
    --tw-text-opacity: 1;
    color: rgb(90 23 23 / var(--tw-text-opacity));
  }
}

@media (width >= 1536px) {
  .\32 xl\:h-16 {
    height: 4rem;
  }

  .\32 xl\:w-\[1408px\] {
    width: 1408px;
  }
}

/*# sourceMappingURL=index.620d1504.css.map */
